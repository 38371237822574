import React, { useState, useRef } from "react"

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;
const KILO_BYTES_PER_BYTE = 1000;

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
    label, 
    updateFilesCb,
    maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    ...otherProps
}) => {
    const fileInputField = useRef(null);
    const [files, setFiles] = useState({});
    const handleUploadBtnClick = () => {
      fileInputField.current.click();
    };
    const handleNewFileUpload = (e) => {
      const { files: newFiles } = e.target;
      if (newFiles.length) {
        let updatedFiles = addNewFiles(newFiles);
        setFiles(updatedFiles);
        callUpdateFilesCb(updatedFiles);
      }
    };
    const addNewFiles = (newFiles) => {
      for (let file of newFiles) {
        if (file.size <= maxFileSizeInBytes) {
          if (!otherProps.multiple) {
            return { file };
          }
          files[file.name] = file;
        }
      }
      return { ...files };
    };
    const convertNestedObjectToArray = (nestedObj) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);

  const callUpdateFilesCb = (files) => {
  const filesAsArray = convertNestedObjectToArray(files);
  updateFilesCb(filesAsArray);
};

    return(
        <>
        <section>
          <label>{label}</label>
          <p>Drag and drop your files anywhere or</p>
          <button type="button" onClick={handleUploadBtnClick}>
            <i className="fas fa-file-upload" />
            <span> Upload {otherProps.multiple ? "files" : "a file"}</span>
          </button>
          <input
            type="file"
            onChange={handleNewFileUpload}
            ref={fileInputField}
            title=""
            value=""
            {...otherProps}
          />
        </section>
  
        {/*second part starts here*/}
        <article>
          <span>To Upload</span>
          <section>
            {Object.keys(files).map((fileName, index) => {
              let file = files[fileName];
              let isImageFile = file.type.split("/")[0] === "image";
              return (
                <section key={fileName}>
                  <div>
                    {isImageFile && (
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`file preview ${index}`}
                      />
                    )}
                    <div isImageFile={isImageFile}>
                      <span>{file.name}</span>
                      <aside>
                        <span>{convertBytesToKB(file.size)} kb</span>
                        <i className="fas fa-trash-alt" />
                      </aside>
                    </div>
                  </div>
                </section>
              );
            })}
          </section>
        </article>
      </>
        
    )
}

export default FileUpload;