import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../../components/pageheader"
import Padding from "../../components/padding"
import styled from "styled-components"
import Layout from "../../components/layout"
import Stappen from "../../components/stappen"
import Stappen2 from "../../components/stappen2"
import SEO from "../../components/seo"
import { device } from "../../queries"
import Stappenplan from "./stappenplan-recycling.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight,faDotCircle, faCheck } from "@fortawesome/free-solid-svg-icons"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Img from "gatsby-image"
import Inkoopform from "../../components/inkoopform"


const Margin = styled.div`
  height: 60px;
`

const Styledcrumbs = styled(Breadcrumbs)`
    .MuiBreadcrumbs-separator{
        font-weight: 600;
    }
`
const Crumblink = styled(Link)`
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: var(--blue-primary);
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    &:hover{
        text-decoration: none;
        color: var(--blue-primary-h); 
    }
    &[aria-current="page"]{
        color: rgba(100,100,100);
        cursor: text;
        
        &:hover{
          

        }
    }
`

const Maincontent = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 60px;
`

const Paragraph = styled.p`
    padding: 10px 0;
`

const Itemlist = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    li{
        font-weight: 600;
        width: 50%;
        padding: 5px 0;
        @media ${device.tablet}{
            width: 100%;
        }
    }
`

const StyledLiIcon = styled(FontAwesomeIcon)`
    color: var(--green-primary);
`

const Subheader = styled.h2`
    font-weight: 700;
    font-size: 20px;
    color: var(--green-text);
`

const Containerpad = styled.div`
`
const Vertpad = styled.div`
    padding-bottom: 50px;
    @media ${device.laptopL}{
        padding: 0 20px 60px 20px;
    }

    @media ${device.mobileL}{
        padding: 0 15px 60px 15px;
    }
`


const Flexdivider = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>*{
        width: 48%;
        @media ${device.tablet}{
        width: 100%;
        &:last-of-type{
            padding-bottom: 0;
        }
        }

    }
    @media ${device.tablet}{
        flex-direction: column;
    }

`

const Checkgrid = styled.div`
    display: inline-grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    gap: 15px 20px;
    padding: 10px 0;
    padding-top: 30px;
    @media ${device.tablet}{
        grid-template-columns: 100%;
    }


`
const Griditem = styled.div`
    display: flex;
    align-items: start;
    p{
        padding: 4px 10px;
    }

`

const Sbr = styled.br`

`

const Icoon = styled(FontAwesomeIcon)`
    font-size: 25px;
    display: inline-block;
    color: var(--green-primary);
`
function handleClick(event) {
    console.info("You clicked a breadcrumb.")
}

const Headerimage = ({something, className}) => {
    const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "diensticonen/recycling.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        anotherImage: file(relativePath: { eq: "recycling/recycling.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000 maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        softdes: file(relativePath: { eq: "datavernietiging/softwarematige_dataverwijdering.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600 maxHeight: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mechdes: file(relativePath: { eq: "datavernietiging/mechanische_dataverwijdering.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600 maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
        }
      }
    `)
    const image = [<Img className={className} fluid={data.placeholderImage.childImageSharp.fluid} />,<Img className={className} fluid={data.anotherImage.childImageSharp.fluid}/>, <Img className={className} fluid={data.softdes.childImageSharp.fluid} />, <Img className={className} fluid={data.mechdes.childImageSharp.fluid} />]
    return image[something];
    }


const Recycling = () => (
    
  <Layout>
    <SEO title="Recycling" />
    <PageHeader title="Recycling" bannerimage={<Headerimage something="1"/>}>
        <Styledcrumbs aria-label="breadcrumb">
        <Crumblink href="/" onClick={handleClick}>
            Home
          </Crumblink>
          <Crumblink href="/diensten" onClick={handleClick}>
            Diensten
          </Crumblink>
          <Crumblink aria-current="page" href="/diensten/Recycling/" onClick={handleClick}>
            Recycling
          </Crumblink>
        </Styledcrumbs>
    </PageHeader>
        <Maincontent>
            <Containerpad>
                <Vertpad>
                    <Subheader>Gratis Recycling IT apparatuur</Subheader>
                    <Paragraph>
                    Wilt u uw oude en overtollige computers, monitoren, laptops, servers, telefoons of harde schijven kwijt en tegelijkertijd de zekerheid van milieuverantwoordelijke recycling van apparatuur en materialen ? Green Digital kan dit kosteloos voor bedrijven verzorgen. Daarbij verwijderen wij altijd de gegevens op datadragers en op aanvraag kan de datavernietiging worden verzorgd met een officieel certificaat en rapportage als bewijslast voor de AVG. 
                    </Paragraph>
                </Vertpad>
                <Vertpad>
                    <Subheader>Bulk of kleine hoeveelheden</Subheader>
                    <Paragraph>
                    Green Digital is gespecialiseerd in de recycling van alle ICT apparatuur incl voedingen, netsnoeren, kabels en aanverwante componenten en elektronica. Een opsomming van de apparatuur en materialen: 
                    </Paragraph>
                            
                                <Itemlist>
                                    <li><StyledLiIcon icon={faDotCircle} /> Computers</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Processoren</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Geheugenkaarten</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Routers</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Switches</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Harde schijven</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Voedingen en kabels</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Processoren</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> CD en DVD drives</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Mobiele telefoons</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Tablets</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Servers</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Datakasten</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Printers</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Thin clients</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> UPS-systemen</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Printplaten</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Overige ICT-apparatuur en elektrische apparaten</li>
                                </Itemlist>
                                <Paragraph>
                                Green Digital neemt al uw ICT apparatuur in, kleine hoeveelheden vanaf 200 kg maar ook grotere hoeveelheden in bulk. Zo bent u verzekerd dat alle apparatuur wordt afgenomen en u niet nogmaals zelf apparatuur moet afvoeren. 
                                </Paragraph>
                    
                </Vertpad>
                <Vertpad>
                <Subheader>Wat zijn de voordelen van dataverwijdering door Green Digital?</Subheader>
                    <Checkgrid>
                        <Griditem><Icoon icon={faCheck}/><p><b>Milieuvriendelijk: </b>Door te kiezen voor recycling van IT hardware, draagt u bij aan het tegengaan van verspilling van waardevolle grondstoffen en de reductie van CO2</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Afhaal op afroep: </b>Afhaal op afspraak in heel Nederland en België</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Kosteloze afvoer: </b>Wij verzorgen de logistiek én afvoer van de apparatuur</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Gedocumenteerd: </b>Als bewijs van succesvolle vernietiging ontvangt u van alle datadragers een officieel datavernietigingscertificaat. .</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Conform wettelijke voorschriften:  </b>wij zijn in het bezit van de verplichte vergunningen voor afvoer en verwerking van elektronische apparatuur.</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Frequente afvoer en datavernietiging: </b>Heeft u regelmatig gegevensdragers die moeten worden vernietigd ? Dan bieden wij u de mogelijkheid om o.b.v. een contract en een afvoerschema, het transport en de vernietiging van uw gegevensdragers te verzorgen.</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>AVG/GDPR compliant: </b>100% veilige dataverwijdering en datadragervernietiging</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Social Return: </b>Wij bieden de mogelijkheid om de restwaarde te doneren aan een goed doel naar keuze</p></Griditem>
                    </Checkgrid>
                </Vertpad>
                <Vertpad>
                    <Subheader>Ons recycleproces uitgelijnd</Subheader>
                    <Paragraph>
                   Onze werkwijze bestaat uit verschillende fasen:
                    </Paragraph>
                    <Stappen2 stappenplan={Stappenplan} />
                </Vertpad>
            </Containerpad>

        </Maincontent>
    <Padding/>
  </Layout> 
)

export default Recycling


