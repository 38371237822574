import React from "react"
import styled from "styled-components"
import Button from '@material-ui/core/Button'
import {device} from "../queries"
import FileUpload from "./fileupload"
import gql from "graphql-tag"
import { Mutation } from 'react-apollo'



const Styledform = styled.form`

    max-width: 800px;
    margin: 0 auto;
    background-color: rgba(0,0,0,0.05);
    box-shadow: inset 0 0 10px rgb(0 0 0 / 10%);
    input{
        padding: 10px;
        border: 1px solid rgba(0,0,0,0.3);
        &:focus{
            outline-color: var(--green-primary);
        }

    }
    label{
        margin-bottom: 40px;
        display: flex;
        justify-content: flex-start;
        @media ${device.tablet}{
            flex-direction: column;

        }
        p{
            display: inline-block;
            font-weight: 600;
            font-size: 18px;
            min-width: 140px;
        }
    }
`
const Innerform = styled.div`
    padding: 50px 30px ;
`

const Otherinput = styled.input`
        max-width: 300px;
        width: 100%;
`
const Styledbutton = styled(Button)`
    background-color: var(--green-primary-full);
    padding: 5px  10px;
    .MuiButton-label{
        font-size: 18px;
    }
    margin: 0 auto;
    display: block;
    &:hover{
        background-color: var(--green-primary);
    }
    font-family: "lato";
    font-weight: 900;
    font-size: 13px;
`

const Buttonholder = styled.div`
    width: 100%;
`


const Noteinput = styled.textarea`
    max-width: 500px;
    width: 100%;
    height: 200px;
    vertical-align: top;
    writing-mode: horizontal-tb;
    border: 1px solid rgba(0,0,0,0.3);
        &:focus{
            outline-color: var(--green-primary);
        }

`
const Formheader = styled.div`
    background-color: var(--green-primary-full);
    padding: 30px;
    p{
        padding-top: 10px;
    }
    h3{
        font-weight: 400;
    }


 
`

const CONTACT_MUTATION = gql`
    mutation CreateSubmissionMutation(
        $clientMutationId: String!, 
        $naam: String!, 
        $organisatie: String!,
        $email: String!,
        $telefoon: String!,
        $notitie: String!
    ){
        createSubmission(
            input:{
                clientMutationId: $clientMutationId, 
                naam: $naam, 
                organisatie: $organisatie,
                email: $email,
                telefoon: $telefoon,
                notitie: $notitie
            }){
                success
                data
            }
    }
`


const Inkoopform = () => { 

    
    const [naamValue, setName] = React.useState("naam");
    const [organisatieValue, setOrganisatie] = React.useState("organisatie");
    const [emailValue, setEmail] = React.useState("email");
    const [telefoonValue, setTelefoon] = React.useState("2312321");
    const [notitieValue, setNotitie] = React.useState("Notitie");
    const {itemValue, setItem} = React.useState("item");


        return(
        <Mutation mutation={CONTACT_MUTATION}>
            {(createSubmission, { loading, error, data })=>(
        <>
        <Styledform onSubmit={async event => {
            event.preventDefault()
            createSubmission({
                variables: {
                    clientMutationId: 'exampleValue',
                    naam: naamValue,
                    organisatie: organisatieValue,
                    email: emailValue,
                    telefoon: telefoonValue,
                    notitie: notitieValue

                }
            })
        }}>
        <Formheader>
            <h3>Vraag vrijblijvende waardebepaling aan</h3>
            <p>Middels dit formulier kunt u een verzoek indienen om een offerte te ontvangen voor de vernietiging van de gegevensdragers en eventuele overname van de bijbehorende apparatuur.</p>
        </Formheader>
         <Innerform>
                <label htmlFor='naamValue'>
                  <p>Uw naam:</p> 
                  <Otherinput onChange={(e)=> (setName(e.target.value))} type="text" value={naamValue} />
                </label>
                <label>
                <p>Organisatie:</p> 
                  <Otherinput onChange={(e)=> (setOrganisatie(e.target.value))} type="text" value={organisatieValue} />
                </label>
                <label >
                <p>Email:</p> 
                  <Otherinput onChange={(e)=> (setEmail(e.target.value))} type="text" value={emailValue} />
                </label>
                <label htmlFor='telefoonValue'>
                <p>Telefoon:</p> 
                  <Otherinput onChange={(e)=> (setTelefoon(e.target.value))} type="number" value={telefoonValue} />
                </label>
                <label htmlFor='opmerkingValue'>
                <p>Opmerking:</p> 
                  <Noteinput type="text" onChange={(e)=> (setNotitie(e.target.value))}  value={notitieValue} ></Noteinput>
                </label>

                <Buttonholder>
                <Styledbutton type="submit">verzenden</Styledbutton>
                </Buttonholder>

           </Innerform>
        </Styledform>
                <div>
                {loading && <p>Loading...</p>}
                {error && (
                  <p>An unknown error has occured, please try again later...</p>
                )}
                {data && <p>yeah boi</p>}
                </div>
        </>

        )}
        </Mutation>
        )
}

export default Inkoopform;